<template>
  <div class="moments-con">
    <div class="moments-title">
      <span class="moments-title-text">朋友圈</span>
      <div class="addBtn" @click="formVisible=true"><i class="el-icon-plus"></i></div>
    </div>
    <div class="infinite-list-wrapper" style="overflow:auto">
      <ul
        class="list"
        v-infinite-scroll="load"
        infinite-scroll-disabled="disabled"
        style="margin: 0">
        <li v-for="post in posts" class="list-item">
          <div class="post">
            <div class="post-info">
              <img :src="post.userData.avatar" class="post-info-avatar">
            </div>
            <div class="post-content">
              <div class="post-content-header">
                <span class="post-info-name">{{ post.userData.name }}</span>
                <span class="post-time">
                  {{ formatDate(post.timestamp) }}
                  <!--                  <i v-if="isDay(post.timestamp)" class="hmyIconfont hmyIcon-taiyang" style="font-size: 24px;color: #FABE2C"></i>-->
                  <!--                  <i v-else class="hmyIconfont hmyIcon-yueliang" style="font-size: 20px;color: #733ff1"></i>-->

                </span>
              </div>
              <div v-html="emoji.decoder.decode(post.postContent.text)"></div>
              <div v-if="post.postContent.images.length > 0" class="post-content-images">
                <el-image
                  v-for="image in post.postContent.images"
                  :src="image"
                  fit="cover"
                  :preview-src-list="[image]" lazy>
                </el-image>
              </div>
              <div class="post-content-footer">
                <span>
                      <span v-if="post.likeList.length>0" style="font-size: 13px">{{post.likeList.length}}人觉得很赞</span>
                </span>
                <span>
                  <i class="el-icon-delete-solid" v-if="post.userData.id===currentUser.id"
                     style="font-size: 18px" @click="deletePost(post.postId)"></i>
                <i class="hmyIconfont hmyIcon-dianzan" style="font-size: 18px"
                   @click="addOrRemoveLike(post.postId)"
                   :class="{ likeActive: isliked(post.likeList) }" ></i>
                <i class="hmyIconfont hmyIcon-pinglun" @click="post.inputVisible=!post.inputVisible"></i>
                </span>
              </div>
              <div class="post-content-comment-list">
                <div v-for="comment in post.comments" style="margin: 4px;display: flex;">
                  <span>
                     <span style="color: #2979FF">{{ comment.userName }}：</span><span>{{ comment.text }}</span>
                  </span>
                  <i class="el-icon-delete-solid commentDeleteBtn" v-if="comment.userId===currentUser.id"
                     style="font-size: 16px;margin-left: 20px" @click="deleteComment(comment.commentId)"></i>
                </div>
                <el-input
                  placeholder="请输入内容"
                  v-model="post.commentContext"
                  clearable size="small"
                  v-if="post.inputVisible">
                  <el-button slot="append" @click="addComment(post.postId,post.commentContext)">发表</el-button>
                </el-input>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div v-if="loading" style="display: flex;justify-content: center;padding: 5px;">加载中...</div>
      <div v-if="noMore" style="display: flex;justify-content: center;padding: 5px;">没有更多了</div>
    </div>

    <div class="form-box" v-if="formVisible">
      <div class="form-box-main">
        <div class="form">
          <div class="action-box">
            <div class="action-bar">
              <div class="action-item">
                <div v-if="emoji.visible" class="emoji-box">
                  <img
                    v-for="(emojiItem, emojiKey, index) in emoji.map"
                    class="emoji-item"
                    :key="index"
                    :src="emoji.url + emojiItem"
                    @click="chooseEmoji(emojiKey)"
                  />
                </div>
                <i class="iconfont icon-smile" title="表情" @click="showEmojiBox"></i>
              </div>

            </div>

            <div class="input-box">
              <textarea ref="input" @focus="onInputFocus" @keyup.enter="" @keyup.shift.enter="nextLine"
                        v-model="form.text" maxlength="700"
                        autocomplete="off" class="input-content"
                        placeholder="这一刻的想法...（Shift + Enter换行）"></textarea>
            </div>
            <div class="input-images">
              <el-image
                v-for="image in form.images"
                :src="imageURL(image)"
                :preview-src-list="[imageURL(image)]"
                fit="cover"
                style="width: 75px;height: 75px;border-radius: 8px;margin: 3px"
              >
              </el-image>

              <label for="img-input" class="add-image-btn" title="图片">
                <i class="el-icon-plus"></i>
              </label>
              <input v-show="false" id="img-input" accept="image/*" multiple type="file"
                     @change="addImage"/>
            </div>
            <div class="send-box">
              <el-button size="medium" type="danger" plain @click="formVisible=false">关闭</el-button>
              <el-button size="medium" type="primary" plain @click="addPost">发表</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {formatDate} from '../utils/utils.js';
  import EmojiDecoder from '../utils/EmojiDecoder';
  import emoji from "@/utils/emoji";

  export default {
    data() {
      const emojiUrl = 'https://imgcache.qq.com/open/qcloud/tim/assets/emoji/';
      const emojiMap = emoji.emojiMap;
      return {
        currentUser: null,

        loading: false,
        count: 10,

        posts: [],

        //定义表情列表
        emoji: {
          url: emojiUrl,
          map: emojiMap,
          visible: false,
          decoder: new EmojiDecoder(emojiUrl, emojiMap),
        },

        formVisible: false,
        form: {
          text: '',
          images: []
        },
        dialogImageUrl: '',
        dialogVisible: false,
      }
    },
    computed: {
      noMore() {
        return this.posts.length >= 0
      },
      disabled() {
        return this.loading || this.noMore
      }
    },
    created() {
      this.getAllPost();
      this.currentUser = this.globalData.currentUser;
    },
    methods: {
      formatDate,
      load() {
        this.loading = true
        setTimeout(() => {
          this.count += 10
          this.loading = false
        }, 100)
      },
      isDay(timeStamp) {
        const date = new Date(timeStamp * 1000); // 将时间戳转换为 JavaScript Date 对象
        const hour = date.getHours(); // 获取小时
        return hour >= 6 && hour < 18; // 6点到18点之间为白天，返回 true；其余时间为夜晚，返回 false
      },
      getAllPost() {
        this.$http.get("/post/all").then(res => {
          for (let post of res.data) {
            post.commentContext = '';
            post.inputVisible = false;
          }
          this.posts = res.data;
          console.log(this.posts)
        })
      },
      async addPost() {
        if (this.form.text === '' || this.form.text === null) {
          this.$message.error('发表的内容为空');
          return;
        }
        let imageURL = [];
        for (let i = 0; i < this.form.images.length; i++) {
          let formData = new FormData();
          formData.append('image', this.form.images[i]);
          await this.$http.post("/upload/image", formData, {
            "Content-Type": "multipart/form-data;charset=utf-8"
          }).then(res => {
            let url = res.data.data;
            imageURL.push(url);
          })
        }

        let post = {
          userId: this.currentUser.id,
          postContent: {
            text: this.form.text,
            images: imageURL
          },
          timestamp: Date.now(),
        }
        this.$http.post("/post/add", post).then(res => {
          console.log(res.data)
          if (res.data) {
            this.formVisible = false;
            this.getAllPost();
            this.$message({
              showClose: true,
              message: '发表成功',
              type: 'success'
            });
            this.form.text='';
            this.form.images=[];
          } else {
            this.$message.error('发表失败');
          }
        })
      },
      nextLine() {
        this.text += '\n';
      },
      onInputFocus() {
        this.emoji.visible = false;
      },
      showEmojiBox() {
        this.emoji.visible = !this.emoji.visible;
      },
      chooseEmoji(emojiKey) {
        this.form.text += emojiKey;
        this.emoji.visible = false;
      },
      addImage(e) {
        [...e.target.files].forEach(file => {
          this.form.images.push(file);
        });
      },
      imageURL(file) {
        return window.URL.createObjectURL(file);
      },
      deletePost(postId) {
        this.$confirm('确定要删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.delete("/post/delete/" + postId).then(res => {
            if (res.data) {
              this.getAllPost();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            } else {
              this.$message.error('删除失败');
            }
          })
        })
      },
      addComment(postId, commentContext) {
        let comment = {
          "postId": postId,
          "text": commentContext,
          "userId": this.currentUser.id,
          "userName": this.currentUser.name
        }
        this.$http.post("/post/addcomment", comment).then(res => {
          if (res.data) {
            this.getAllPost();
          } else {
            this.$message.error('发表失败');
          }
        })
      },
      deleteComment(commentId) {
        this.$confirm('确定要删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.delete("/post/deletecomment/" + commentId).then(res => {
            if (res.data) {
              this.getAllPost();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            } else {
              this.$message.error('删除失败');
            }
          })
        })
      },
      addOrRemoveLike(postId) {
        this.$http.put("/post/like/addorremove/" + postId + "/" + this.currentUser.id).then(res => {
          if (res.data) {
            this.getAllPost();
            console.log("点赞或移除点赞成功");
          } else {
            console.log("点赞或移除点赞失败");
          }
        })
      },
      isliked(likeList){
        return likeList.indexOf(this.currentUser.id) !== -1;
      }
    }
  }
</script>


<style scoped>
  .moments-con {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .moments-title {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #dcdfe6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .moments-title-text {
    font-size: 15px;
  }

  .addBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    position: absolute;
    right: 20px;
    border-radius: 50%;
    width: 22px;
    height: 22px;
  }

  .addBtn:hover {
    background-color: #c8c8c8;
    color: white;
    cursor: pointer;
  }

  .infinite-list-wrapper {
    max-width: 600px;

    width: 100%;
    height: 100%;

    overflow-y: auto;
    scrollbar-width: thin;
  }

  .infinite-list-wrapper::-webkit-scrollbar {
    width: 0;
  }

  .list {
    padding: 5px;
  }

  .list-item {
    padding: 5px;
    border-bottom: 0.5px solid #dcdfe6;

  }

  .post {
    display: flex;
    width: 100%;
  //background-color: #f0f0f0; border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
  }

  .post-info {
    display: flex;
  }

  .post-info-avatar {
    width: 40px;
    height: 40px;
  }

  .post-content-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .post-info-name {
    font-size: 16px;
  }

  .post-time {
    font-size: 13px;
    color: #909399;
  }

  .post-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10px 0 10px;
    font-size: 14px;
    color: #303133;
  }

  .post-content-images {
    margin-top: 10px;

  }

  .post-content-images .el-image {
    width: 85px;
    height: 85px;
    margin: 2px;
    border-radius: 4px;
  }

  .post-content-footer {
    display: flex;
    margin: 10px;
    font-size: 14px;
    color: #909399;
    justify-content: space-between;
    align-items: flex-end;
  }

  .post-content-footer i {
    margin-left: 15px;
    cursor: pointer;
  }

  .post-content-footer i:hover {
    //color: #1E88E5;
  }

  .post-content-footer i:active {
    color: #1E88E5;

  }

  .post-content-footer .el-icon-delete-solid:hover {
    color: #F56C6C;
  }

  .likeActive{
    color: #1E88E5;
  }

  .post-content-comment-list {
    font-size: 13px;
    color: #909399;
  }

  .commentDeleteBtn {
    display: none;
    color: #F56C6C;
    cursor: pointer;
  }

  .post-content-comment-list div:hover .commentDeleteBtn {
    display: block;
  }

  .form {
    width: 100%;
  //height: 140px; background: #FFFFFF;
  }

  .action-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .action-bar {
    display: flex;
    flex-direction: row;
    padding: 0 5px;
  }

  .action-bar .action-item {
    text-align: left;
    padding: 10px 0;
    position: relative;
  }

  .action-bar .action-item .iconfont {
    font-size: 22px;
    margin: 0 10px;
    z-index: 3;
    color: #606266;
    cursor: pointer;
  }

  .action-bar .action-item .iconfont:focus {
    outline: none;
  }

  .action-bar .action-item .iconfont:hover {
    color: #1E88E5;
  }

  .emoji-box {
    width: 306px;
    height: 200px;
    overflow: auto;
    scrollbar-width: thin;
    position: absolute;
    left: 35px;
    top: -190px;
    z-index: 2007;
    background: #fff;
    border: 1px solid #ebeef5;
    padding: 2px;
    text-align: justify;
    font-size: 14px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    word-break: break-all;
    border-radius: 4px;
  }

  .emoji-box::-webkit-scrollbar {
    width: 0;
  }

  .emoji-item {
    width: 30px;
    height: 30px;
    margin: 0 2px;
  }

  .input-box {
    padding: 0 10px;
    flex: 1;
  }

  .input-content {
    border: none;
    resize: none;
    display: block;
    padding: 5px 5px;
    box-sizing: border-box;
    width: 100%;
    color: #606266;
    outline: none;
    background: #FFFFFF;
    word-break: break-all;
    height: 80px;
    font-size: 16px;
  }

  .input-images {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  .add-image-btn {
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 8px;
    cursor: pointer;
    font-size: 20px;
    color: #909399;
    margin: 3px;
  }

  .add-image-btn:hover {
    background-color: #dcdcdc;
    color: white;
  }

  .send-box {
    padding: 5px 10px;
    text-align: right;
  }

  .form-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(51, 51, 51, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-box-main {
    width: 350px;
  //height: 200px; background: #ffffff; z-index: 100; border-radius: 10px; //overflow: hidden; padding: 5px;
  }

  @media screen and (max-width: 700px) {
    .form-box {
      width: 100%;
      left: 0;
    }
  }

</style>